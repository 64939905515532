var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "LBL0000319", noMarginPadding: true },
        },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c("q-toggle", {
                attrs: {
                  "left-label": "",
                  color: "red",
                  "checked-icon": "check",
                  "unchecked-icon": "clear",
                  "true-value": "Y",
                  "false-value": "N",
                  label: "위치 직접 표시",
                },
                on: { input: _vm.locationMarkFlagChange },
                model: {
                  value: _vm.workPermit.locationMarkFlag,
                  callback: function ($$v) {
                    _vm.$set(_vm.workPermit, "locationMarkFlag", $$v)
                  },
                  expression: "workPermit.locationMarkFlag",
                },
              }),
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable &&
                  _vm.isWriting &&
                  _vm.workPermit.locationMarkFlag === "Y"
                    ? _c("c-btn", {
                        attrs: { label: "LBL0000253", icon: "add" },
                        on: { btnClicked: _vm.addArea },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  _vm.isWriting &&
                  _vm.workPermit.locationMarkFlag === "Y"
                    ? _c("c-btn", {
                        attrs: { label: "LBL0000254", icon: "place" },
                        on: { btnClicked: _vm.selectMap },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 container",
              },
              [
                _c(
                  "q-img",
                  {
                    style: {
                      height: "800px",
                      width: String(_vm.ratio * 800) + "px",
                    },
                    attrs: {
                      src: _vm.mapSrc,
                      "spinner-color": "white",
                      contain: true,
                    },
                  },
                  [
                    _vm.workPermit.maps && _vm.workPermit.maps.length > 0
                      ? _vm._l(_vm.workPermit.maps, function (item, idx) {
                          return _c(
                            "VueDraggableResizable",
                            {
                              key: idx,
                              ref: "markImage",
                              refInFor: true,
                              staticClass: "markImage",
                              attrs: {
                                resizable: false,
                                parent: true,
                                draggable:
                                  _vm.editable &&
                                  _vm.isWriting &&
                                  _vm.workPermit.locationMarkFlag === "Y",
                                x: item.locationXcoordinate,
                                y: item.locationYcoordinate,
                                w:
                                  _vm.workPermit.locationMarkFlag === "Y"
                                    ? 40
                                    : item.locationWidth,
                                h:
                                  _vm.workPermit.locationMarkFlag === "Y"
                                    ? 40
                                    : item.locationHeight,
                                grid: [10, 10],
                              },
                              on: {
                                dragging: (x, y) => _vm.onDrag(x, y, item),
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.workPermit.locationMarkFlag === "Y"
                                      ? idx + 1
                                      : item.locationName
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 container",
              },
              [
                _vm.workPermit.maps && _vm.workPermit.maps.length > 0
                  ? _vm._l(_vm.workPermit.maps, function (item, idx) {
                      return _c("div", { key: idx, staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-10" },
                          [
                            _c("c-multi-select", {
                              ref: "mark-work-multi-select-" + item.id,
                              refInFor: true,
                              staticClass: "q-pt-md",
                              attrs: {
                                disabled:
                                  !_vm.isWriting ||
                                  _vm.workPermit.locationMarkFlag === "N",
                                editable: _vm.editable,
                                comboItems: _vm.supplyItems,
                                isObject: true,
                                valueText: "sopWorkTypeName",
                                valueKey: "sopWorkTypeCd",
                                itemText: "codeName",
                                itemValue: "code",
                                label:
                                  _vm.workPermit.locationMarkFlag === "Y"
                                    ? _vm.$comm.getLangLabel(
                                        "LBL0000320",
                                        "SWP"
                                      ) +
                                      (idx + 1)
                                    : item.locationName,
                                name: "resultTypes",
                              },
                              on: { datachange: _vm.datachange },
                              model: {
                                value: item.resultTypes,
                                callback: function ($$v) {
                                  _vm.$set(item, "resultTypes", $$v)
                                },
                                expression: "item.resultTypes",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-2",
                            staticStyle: { position: "relative", top: "20px" },
                          },
                          [
                            _vm.editable &&
                            _vm.isWriting &&
                            _vm.workPermit.locationMarkFlag === "Y"
                              ? _c("c-btn", {
                                  attrs: { icon: "remove" },
                                  on: {
                                    btnClicked: function ($event) {
                                      return _vm.removePoint(idx)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    })
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }